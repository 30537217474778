/* put main id name as prefix to all of these*/

.bigTitle{
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 2.75rem;
}

.all-subjects-list { /* mobile-first */
    columns: 1 auto;
}

@media only screen and (min-width: 600px) { /* tablet */
    .all-subjects-list {
        columns: 2 auto;
    }
}

@media only screen and (min-width: 768px) { /* laptop*/
    .all-subjects-list {
        columns: 4 auto;
    }
}

.all-subjects-list .college {
    width: 100%;
    display: inline-block;
}

.all-subjects-list .college .college-name {
    font-size: 1.5rem;
    font-weight: bold;
    width: 100%;
    display: block;
} 

.all-subjects-list .college .college-subject {
    cursor: pointer;
} 

.font-color-asu {
  color: #8C1D40!important;
  font-size: 18px;
}