.uds-table {
  border: 1px solid $uds-color-base-gray-2;
  overflow-x: auto;

  & > table {
    width: 100%;

    th,
    td {
      box-sizing: border-box;
      font-size: 1rem;
      text-align: left;
      padding: 1rem;
      max-width: 700px;
    }

    tr {
      :nth-child(n + 1) {
        box-sizing: border-box;
      }

      :first-child {
        box-sizing: border-box;
      }
    }

    > thead {
      > tr {
        &:first-child th {
          background-color: $uds-color-base-gray-3;
        }
      }
    }

    tbody {
      tr {
        th,
        td {
          background-color: white;

          p {
            margin-bottom: 0;
          }
        }
        &:nth-child(2n) {
          th,
          td {
            background-color: $uds-color-base-gray-1;
          }
        }

        &:hover,
        &:focus {
          th,
          td {
            background-color: $uds-color-base-gray-2;
          }
        }

        > th {
          &.normal {
            font-weight: normal;
          }

          &.indent {
            font-weight: normal;
            padding-left: 4rem;

            @media screen and (max-width: $uds-breakpoint-sm) {
              padding-left: 2rem;
            }
          }
        }
      }
    }
  }

  caption, figcaption {
    background-color: var(--bs-gray-1);
    caption-side: top;
    font-size: 0.85rem;
    padding: 1rem;
    text-align: center;
    word-break: break-word;
  }
}

.uds-table-fixed {
  overflow-x: auto;
  border: 1px solid $uds-color-base-gray-2;
  scroll-behavior: smooth;
  &-wrapper {
    .scroll-control {
      display: none;
    }
  }

  &-wrapper:hover {
    .scroll-control {
      display: block;
    }
  }

  &-wrapper {
    position: relative;

    .scroll-control {
      display: none;
      position: absolute;
      height: 100%;
      pointer-events: none;
      top: 0;
      z-index: 100;

      &.previous {
        background: linear-gradient(
          90deg,
          rgba(25, 25, 25, 0.25) 0%,
          rgba(25, 25, 25, 0) 100%
        );
        left: 315px;
      }

      &.next {
        background: linear-gradient(
          90deg,
          rgba(25, 25, 25, 0) 0%,
          rgba(25, 25, 25, 0.25) 100%
        );
        right: 0;
      }

      button {
        pointer-events: all;
        margin: 0 8px;
        position: relative;
        top: 50%;
      }
    }
  }

  & > table {
    border: none;

    tr > *:first-child {
      left: 0;
      position: sticky;
    }

    tr > *:nth-child(n + 2),
    td {
      box-sizing: border-box;
    }
  }
}

@media screen and (max-width: $uds-breakpoint-md) {
  .uds-table-fixed {
    &-wrapper {
      overflow-x: hidden;
      .scroll-control {
        pointer-events: none;
        display: block;

        &.previous button,
        &.next button {
          visibility: hidden;
        }
      }
    }
  }
}

@media screen and (max-width: $uds-breakpoint-sm) {
  .uds-table-fixed {
    &-wrapper {
      .scroll-control {
        &.previous {
          left: 128px;
        }
      }
    }
    & > table {
      tr > *:first-child {
        min-width: auto;
      }
    }
  }
}
