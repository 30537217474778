#home-search{
    padding-left: 0px;
    padding-right: 0px;
}

#home-search .sectionLabel{
    font-weight: bold;
    font-size: 20px;
    line-height: 1.75rem;
}

#home-search .sectionLabel:hover{
    text-decoration: underline;
}

#home-search .icon{
    font-size: 24px !important;
    padding-right: 30px;
}   

#home-search .bigTitle{
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 2.75rem;
}

#home-search .text{
    font-size: 1.125rem;
    line-height: 1.6875rem;
}

#home-search .card{
    cursor: pointer;
}



