html, body {
    font-family:"Arial";
      
    
  }
  
  .bg-gray{
    background-color: #EDEDED
  }
  
  .gray-font{
    color: #747474 !important
  }
  
  /* .circle{
    width: 50px;
    height: 50px;
    border-width: 2px !important
  } */
  
  #course-recommend .circle {
   
    border-radius: 200px;
   
    height: 50px;
    font-weight: bold;
    width: 50px;
    display: table;
    border: 2px solid #484848;
    color: #484848;
    opacity: 1;
    
  }
  #course-recommend .circle p {
    vertical-align: middle;
    display: table-cell;
    
  }

  #course-recommend .circle:focus{
    border: 2px solid  #8C1D40!important;
    color:#8C1D40!important;
  }
  
  #course-recommend .rectangle{
    height: 70px;
    
  }

  #course-recommend .carousel-control-next{
    position: relative;
  }
  
  .grading,
  .noLike,
  .yesLike{
    cursor: pointer;
  }
  
  .border-asu {
    border-color: #8C1D40!important;
    color: #8C1D40!important;
  }
  
  #course-recommend .card{
    height: auto;
  }
   
  .card-selected,
  .grading .card-body:hover,
  .grading .card-body:focus{
    border-color: #8C1D40!important;
    color:white!important;
    background-color:  #8C1D40!important;
    font-size: 14px;
    
  
  }
  
  .font-color-asu div:not(.card-selected),
  button.font-color-asu{
    color: #8C1D40!important;
    font-size: 14px
  }
  
  #selectedSubject{
    display: none
  }
  
  .background-asu{
    background-color:  #8C1D40;
    color: white !important
  }
  
  .questions-panel{
    min-height: 225px;
    min-width: 308px
  }
  
  .carousel-control-next, .carousel-control-prev{
    right: unset;
    
    position: unset;
    opacity: unset;
  }
  
  .carousel-indicators{
    bottom: 175px !important;
    /* bottom: 0 !important; */
    display: flex !important;
    justify-content: center !important;
    left: 0 !important;
    list-style: none !important;
    margin-left: 15% !important;
    margin-right: 15% !important;
    padding-left: 0 !important;
    position: absolute !important;
    right: 0 !important;
    z-index: 15 !important;
  }

  .carousel-indicators button{
    border-bottom: 10px solid transparent !important;
    border-top: 10px solid transparent !important;
    flex: 0 1 auto !important;
    height: 3px !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    opacity: .5 !important;
    text-indent: -999px !important;
    transition: opacity .6s ease !important;
    width: 30px !important;
  }
  
  .carousel-indicators button{
    background-color: #9e9a9a !important;
    cursor: default !important;
  }
  
  .carousel-indicators button.active {
    opacity: 1 !important;
    background-color: #8C1D40 !important;
  }
  
   .carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    color: inherit;
    text-decoration: inherit;
    outline: inherit;
    opacity: inherit;
  } 
  
  .fadeOut{
    opacity:0;
    /* width:0;
    height:0; */
    transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
  
  }
  .fadeIn{
    opacity:1;
    /* width:100px;
    height:100px; */
    transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
  
  }
  
  .wasThisUseful,
  .wasThisUseful.btn:hover{
    background-color: #007BB6;
    border: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    max-width: 24rem;
    min-width: 15rem;
    color: white;
    padding: 12px;
  }
  
  .wasThisUseful .badge{
    font-size: 15px;
    background: none;
    color: white;
    cursor: pointer;
    /* border-color: white;
    border-width: 1px */
  }
  .wasThisUseful .badge:focus{
    box-shadow: 0px 0px 0px 2px #fff, 0px 0px 0px 4px #191919 !important;
  }
  


  .wasThisUseful .badge:hover,
  .wasThisUseful .badge.answered{
    background: white;
    color: #007BB6
  }
  
  .wasThisUseful .badges{
    
    float: right;
  }
  
  
  @media (min-width: 768px) {
      
    #course-recommend .circle{
      width: 57px;
      height: 57px;
    }
    
   
  }
  
  /* @media (min-width: 1200px) { 
    .card-body{
      min-height: 110px
    }
  } */
  
  
  @media (max-width: 575.98px) {
    #course-recommend .card-body{
      min-height: 124px;
      padding: 24px 32px 24px 32px
    }
  }

  @media (max-width: 387px) {
    .wasThisUseful .badges{
    
      float: none;
    }
  }
  
  @media (min-width: 576px){
    #course-recommend .card-body{
      min-height: 82px;
      padding: 24px 32px 24px 32px
    }
  }