// ASU Design System Banner styles without Bootstrap variables to override.
.hidden-banner {
  display: none;
}
.banner {
  position: relative;
  display: flex;
  padding: $uds-size-spacing-4 0;

  .banner-icon {
    text-align: left;
    svg {
      font-size: $uds-size-spacing-4;
      margin: calc(#{$uds-size-spacing-1} / 2) $uds-size-spacing-4
        calc(#{$uds-size-spacing-1} / 2) 0;
    }
  }
  .banner-content {
    flex: 5;
    margin-right: $uds-size-spacing-4;
    h3, h1 {
      margin: $uds-size-spacing-1 $uds-size-spacing-0;
      font-size: 1.5rem;
      letter-spacing: -.0525rem;
      line-height: 1.75rem;
    }
  }
  .banner-buttons {
    flex: 3;
    a,
    button {
      margin-top: $uds-size-spacing-1;
      margin-bottom: $uds-size-spacing-1;
      margin-left: 0;
    }
    // Do buttons in their own flexbox, column-style, no stretching.
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .banner-close {
    flex: 2;
    margin-top: -$uds-size-spacing-2;
    margin-right: -$uds-size-spacing-2;
    width: 12px;

    // Edits close button content (i.e. "X" inside bubble)
    .close {
      opacity: 1;
      font-size: $uds-size-font-small;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.banner-green {
  background: $uds-color-base-green 0% 0% no-repeat padding-box;
  a:not(.btn) {
    color: $uds-color-font-dark-base;
  }
  a:visited:not(.btn) {
    color: $uds-color-font-dark-base;
  }
}
.banner-orange {
  background: $uds-color-base-orange 0% 0% no-repeat padding-box;
  a:not(.btn) {
    color: $uds-color-font-dark-base;
  }
  a:visited:not(.btn) {
    color: $uds-color-font-dark-base;
  }
}
.banner-blue {
  background: $uds-color-base-blue 0% 0% no-repeat padding-box;
  a:not(.btn) {
    color: $uds-color-font-dark-base;
  }
  a:visited:not(.btn) {
    color: $uds-color-font-dark-base;
  }
}
.banner-gray {
  background: $uds-color-background-gray 0% 0% no-repeat padding-box;
}
.banner-black {
  background: $uds-color-background-dark 0% 0% no-repeat padding-box;
  color: $uds-color-base-white;
  a:not(.btn) {
    color: $uds-color-font-light-link;
  }
  a:visited:not(.btn) {
    color: $uds-color-font-light-visited;
    // May need the following instead for proper contrast.
    //color: $uds-color-base-gold;
  }
}

// Media Queries
.banner {
  // Mobile tweaks. 576px
  @media screen and (max-width: $uds-breakpoint-sm) {
    flex-direction: column;
    .banner-icon {
      flex: 1;
      margin-bottom: $uds-size-spacing-1;

      svg {
        font-size: $uds-size-spacing-5;
        margin: $uds-size-spacing-0;
      }
    }
    .banner-content {
      margin-bottom: $uds-size-spacing-2;

      h3, h1 {
        margin-bottom: $uds-size-spacing-2;
        font-size: 1.5rem;
        letter-spacing: -.0525rem;
        line-height: 1.75rem;
      }
    }
    .banner-close {
      position: absolute;
      top: $uds-size-spacing-2;
      right: $uds-size-spacing-2;
      margin-top: initial;
      margin-right: initial;
    }
  }
}
