html,
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
  color: #191919;
  text-align: left;
  background-color: #fff;
}

label {
  font-size: 1rem !important;
}

h1 {
  font-size: 1.125rem;
  line-height: 1.2;
}

.ms-minus-1{
  margin: -0.5rem;
}

/* h1.highlight-gold, h2.highlight-gold {
  box-shadow: -0.15em 0 0 #ffc627, 0.15em 0 0 #ffc627;
  background: #ffc627;
  color: #191919;
  font-weight: bold;
  font-size: 4rem;
  line-height: 68px;
}   */

.white-box {
  background: #ffffff;
  margin-left: -1.4rem !important;
}


/* a,
.btn-link {

  color: #8C1D40;
  text-align: left;
  text-decoration: underline;
  letter-spacing: 0px;
  opacity: 1;

  padding: 0px;
  cursor: pointer;
}

a:hover{
  text-decoration: none;
  box-shadow: none;
  color: #8C1D40;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;

  padding: 0px;
}

.btn-link:hover {
  text-decoration: none;
  border: none;
  box-shadow: none;
  color: #8C1D40;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;

  padding: 0px;
} */

a.nav-link:hover {
  padding: .5rem 1rem;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  bottom: 4px;
}

.form-check-label {
  margin-bottom: 0;
  padding-left: 8px;
}

.spinner-button {
  /* display: none; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);

  z-index: 9999;
  padding: 1rem;
  opacity: 1 !important;


}

.spinner-button-styles{
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  border-radius: 400rem;
  padding: 1rem 2rem;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 600;
}

.spinner-button-styles:active,
.spinner-button-styles:focus{
  border: none;
  box-shadow: none;
}
/*
.spinner-button:hover{
  transform: none !important;
  color: #fff !important;
  margin-top: 3rem !important;
} */



.white,
.white:hover {
  color: #fff;
}

/* .btn-link:hover,.btn-link.focus,
.btn-link:focus,
.navbar-nav a:focus,
.navbar-nav a:hover{
  text-decoration: none;
  box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);

} */
/* .underline-dot{
  border-bottom: 1px dotted

} */

/* .page-title{
  padding: 4px 12px;
  background: #FFC627;
  font-style: normal;
  font-weight: bold;
  font-size: 37px;
  text-align: center;
  width: 260px;

} */

.search-title {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.1;
  margin-top: 0 !important;
}


.pageTitle {
  font-weight: 500;
  color: #FFFFFF;
  font-size: 60px;
  line-height: 50px;
  text-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
}



.pointer {
  cursor: pointer;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box,
.react-datepicker__time-container {
  width: 100px !important
}

.search-sec {

  top: 100px;
  background: rgba(26, 70, 104, 0.51);
}

.multiselect {
  white-space: nowrap;

  text-overflow: ellipsis;

}

.jumbotron {

  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fcecfc+0,fcecfc+24,fba6e1+90,fd89d7+100,fd89d7+100,ff7cd8+100 */
  background: none;
  border-radius: 0
}

.jumbotron .multiple-choice-panel {
  background-color: white;
  padding: 10px;
  position: absolute;
  z-index: 100;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 2rem !important;
  }
}

/*This is used to cover a bug of the spinned that adds a blank div where the code is inserted*/
.spinner-span div {
  display: block !important;
}

.btn-xl {
  padding: 1rem 1.5rem;

}

.btn-asu-maroon {
  background: #8C1D40 0% 0% no-repeat padding-box;
  opacity: 1;
  border: none;
}

.btn-asu-maroon:hover {
  background: #8C1D40 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
  transform: scale(.9);
}

/*
.btn-asu-maroon{
  background-color: #8C1D40;
  border-color:#8C1D40;
  color:white
}

.btn-asu-gold,
.btn-asu-gold:hover{
  background-color: #FFC627;
  border-color:#FFC627;
  color: #191919;
  font-size: large;
  padding: 1rem;
}

.btn-asu:hover{
  background-color: #cc275b;
  border-color:#cc275b;
} */

/* .btn-asu-gold:hover{

  color: white;


} */

.asu-font-color,
.asu-font-color:hover {
  color: #8C1D40
}



.upper {
  text-transform: uppercase;
}



/* .asu::placeholder{
  color:rgb(148, 146, 146);
  font-weight: 200
} */



/* Hack for date fields placeholder color*/


/* input[type="date"]::-webkit-datetime-edit-text,
input[type="date"]::-webkit-datetime-edit-month-field,
input[type="date"]::-webkit-datetime-edit-day-field,
input[type="date"]::-webkit-datetime-edit-year-field {
  color: #BFBFBF ;
}



input[type="date"].date-input--has-value::-webkit-datetime-edit-text,
input[type="date"].date-input--has-value::-webkit-datetime-edit-month-field,
input[type="date"].date-input--has-value::-webkit-datetime-edit-day-field,
input[type="date"].date-input--has-value::-webkit-datetime-edit-year-field {
  color: #495057 !important;
} */

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #747474 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #747474 !important;
}

::-webkit-input-placeholder {
  color: #747474 !important;
}

:-moz-placeholder {
  color: #747474 !important;
}

::-moz-placeholder {
  color: #747474 !important;
}

/* Course Item css */

.class-results {
  font-size: 14px;
  padding-left: 45px !important;
  padding-right: 45px !important;
}

.font-16{
  font-size: 16px;
}

.font-14{
  font-size: 14px;
}

input.asu,
select.asu,
button.asu,
input.react-autosuggest__input {
  height: 40px;
  /* border: none; */
  /* font-size: larger; */
  border-radius: 0;
}

input.asu:focus,
select.asu:focus,
button.asu:focus,
input.react-autosuggest__input:focus {
  border: 2px solid #191919 !important;
  box-shadow: none !important;
}


input#subject,
input#catalogNbr,
.react-autosuggest__input {
  text-transform: uppercase;

}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}

::placeholder {
  /* Recent browsers */
  text-transform: none;
}

/*
input.react-autosuggest__input,
input#catalogNbr,input#keyword,
select#term{
    border: 1px solid #ced4da !important
} */

/* input.asu:focus,
select.asu:focus{
    border: 1px solid #ced4da !important;
    box-shadow: none !important;
} */

/* #term{
  padding-bottom: 0.55rem !important;
} */

/* input#keywords{
  border-left: 1px solid #ced4da !important
} */



.holder .form-label {
  /* margin-bottom: -50px; */
  padding-left: 0.5rem;
  padding-top: .25rem;
  position: absolute;
  color: #484848;
  z-index: 2;
  font-size: 0.875rem;
}

.holder input {
  height: 60px;
  padding-top: 20px !important;
  color: #191919;
}

.holder select {
  padding-top: 25px !important;
  color: #191919;
}

.has-feedback i {
  float: right;
  margin-top: -40px;
  margin-right: 10px;
}

.react-autosuggest__input {
  padding: 0.5rem 0.5rem !important;
}

/* input.asu:focus{
  border-color: #2684FF;
  box-shadow:none;
  border-width: 2px
} */

/* #search-button{
  margin-top: 6px;
} */

.advancedPane {
  /* margin-top: 300px; */
  background-color: rgb(229, 229, 229)
}

.subject-columns .title {

  color: #000000;
  font-size: 21px;
  line-height: 24px;
}

.subject-columns .subject {
  color: #8C1D40;
  font-size: 14px;
  line-height: 28px;
}

.rectangle {
  border-radius: 13px;
  background-color: #007BB6;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.rectangle .fa {
  font-size: larger;
  padding-top: 2px;
  cursor: pointer;
}

.link-color,
.link-color:hover {
  color: #8C1D40 !important;
  padding: 0px !important;
}

.page-item.active .page-link {
  background-color: #8C1D40;
  border-color: #8C1D40;
}

.page-link,
.page-link:hover {
  color: #8C1D40;

}

/* .page-link:hover {
  padding: .55rem .75rem;
} */


.hidden-course-url {
  height: 0px;
  width: 0px;
  opacity: 0;
}

/* select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: right 50%;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
  padding: .5em;
  padding-right: 1.5em
} */



@media (min-width: 575.99px) {

  input.asu,
  select.asu,
  button.asu {
    border-radius: 0;

  }

/*

  .css-yk16xz-control {
    border-radius: 0 !important;
  }

  .border-radio-left .css-yk16xz-control {
    border-bottom-left-radius: 4px !important;
    border-top-left-radius: 4px !important;
  }

  .border-radio-right .css-yk16xz-control {
    border-bottom-right-radius: 4px !important;
    border-top-right-radius: 4px !important;
  } */

}

/* #asu_hdr .btn-link{
  color: black;
  font-size: 13px;
  padding: 0px;

} */
/* #asu_hdr .btn-link:hover,
#asu_hdr .btn-link:focus{
  color: #d23153;
  text-decoration: underline;
  box-shadow:none !important;

} */
/* #asu_login_module{
  padding-top: 3px;
} */

#asu_name {
  padding-top: 4px !important;
}

#asu_login_module {


  margin-top: 4px !important;
  height: 17px;
}

.calendar {
  width: 142px !important;
}

/* .react-autosuggest__input{
  border: 1px #ced4da !important
} */

.sort-icon {
  font-size: large;
  margin-left: 10px;
  vertical-align: bottom;
}

/* .sort-column{
  cursor: pointer;
} */


.button-link{
  background: none!important;
  border: none;
  color: #8C1D40;
  /* text-decoration: underline; */
  cursor: pointer;
  padding: 0 !important;
}

.button-link.underline{
  text-decoration: underline;
}

.button-link.black{
  color: #191919;
}

.button-link:hover,
.button-link:focus{
  text-decoration: underline !important;
}

.button-link.underline:hover {
  text-decoration: none !important;
}
.button-link.underline:focus{
  text-decoration: none;
}

/* .filters {
  font-size: small;
} */

.filters input {
  border-radius: 0%;
  font-size: small;
}

.class-table tbody>tr:hover {
  background-color: #e8e8e8;
}




/*
.filter .btn-outline-dark:not(:disabled):not(.disabled).active,
.filter .btn-outline-dark:not(:disabled):not(.disabled):active,
.filter .show>.btn-outline-dark.dropdown-toggle {

  color: #fff;
  background-color: #8C1D40 !important;
  border-color:#8C1D40 !important;

}
.filter .btn-outline-dark:hover{
  background-color: #fff !important;
  color: #212529

}

.filter .btn-outline-dark.focus,
.filter .btn-outline-dark:focus,
.filter .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.filter .btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.filter .show>.btn-outline-dark.dropdown-toggle:focus {

  box-shadow: none
}

.filter .dropdown-toggle::after {
  display: none;

}
*/


/* custom rule
.sort-by-dropdown {
  color: #fff;
  background-color: #000;
  border-color: #000;
  cursor: pointer;
  padding: 1rem 0.1rem 1rem 1rem;
  font-size: 1rem;
  font-weight: 700;
  outline: none;
  //padding: 7px 0px 7px 15px !important;
}*/

/*Toggle input*/
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: #2196F3;
}

input:focus+.slider,
.switch:focus {
  box-shadow: none !important;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  outline: none;
}

.slider.focus{
  outline: 2px solid #191919 !important;
}

button.closeIcon{
  border: none;
}



.slider.round:before {
  border-radius: 50%;
}

.gray-bg {
  background-color: #F5F5F5;
}

.blue-color {
  color: #017BB6
}

#main {
  margin-top: 130px;
}

/*Related classes*/

.related-classes .panel-heading,
.related-classes tr {
  cursor: pointer;
}

.related-classes .panel-default>.panel-heading {
  background-image: none !important;
  background-color: white !important
}

.related-classes .table>tbody>tr>td {
  border: none !important
}

.related-classes .table {
  margin-bottom: 0px !important
}

.related-classes .table>tbody>tr>td.caret {
  width: 44px
}

.related-classes .table>tbody>tr>td.availableSeatsColumnValue {
  width: 80px;
  padding-top: 6px
}

.related-classes .table>tbody>tr>td.instructor {
  max-width: 120px;

}

.related-classes .table>tbody>tr>td.component {
  width: 125px
}

#details-side-panel .related-classes .table>tbody>tr>td.instructor {
  text-align: center;
}

#details-side-panel .related-classes .table>tbody>tr>td {
  font-size: smaller;

}

#details-side-panel .related-classes .table>tbody>tr>td:not(.instructor) {
  white-space: nowrap;
}

#details-side-panel .related-classes .table>tbody>tr>td.days {
  width: 45px
}

#details-side-panel .related-classes .table>tbody>tr>td.time {
  width: 77px;
  text-align: right;
}


.related-classes .btn-asu {
  padding: 6px !important;
  font-size: larger;
  font-weight: 500;
  width: 7% !important;
}

.list .related-classes .btn-asu,
#details-side-panel .related-classes .btn-asu {
  width: 50% !important;
  margin-bottom: 10px;
}

/* .related-classes{
	margin-left: 65px;
	margin-right: 65px
} */

.list .related-classes,
#details-side-panel .related-classes {
  margin-left: 0px;
  margin-right: 0px
}

.list .related-classes .table>tbody>tr>td.availableSeatsColumnValue {
  width: 280px
}

.related-classes tr>td {
  border: none;
}

.related-classes .card-header tr>td {
  padding-top: 0px;
  padding-bottom: 0px;

}

.related-classes .card{
  height: auto !important;
}

.related-classes .card-header {
  padding-bottom: 0px !important;
  padding-top: 10px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
  /* height: 1rem; */
}

.book-info .card-header {
  padding-bottom: 5px !important;
  padding-top: 10px !important;
  /* padding-left: 0px !important; */
  padding-right: 0px !important;
  /* height: 1rem; */
  background-color: #D0D0D0;
  border-radius: 0% !important;
}
#book-info{
  padding-right: 70px;
}

@media (max-width: 767.98px) {
  #book-info{
    padding-right: 10px;
  }
}

.card.book-info{
  border-radius: 0% !important;
}


.bold-hyperlink {
  font-weight: bold !important;
  color: #191919 !important;
  text-decoration: none !important;
}

.bold-hyperlink:hover {
  font-weight: bold !important;
  color: #8C1D40 !important;
  text-decoration: underline !important;
}

.maroon-bold-hyperlink {
  font-weight: bold !important;
  color: #8c1d40 !important;
  text-decoration: none !important;
}

.maroon-bold-hyperlink:hover {
  text-decoration: underline !important;
}

#late-enroll-popover {
  min-width: 25%;
  font-size: 1rem;
}

.page-link.active {
  box-shadow: 0px 0px 0px 2px #fff, 0px 0px 0px 4px #191919 !important;
}

/* .filters .card-header{
  padding-top: 20px !important;
  background-color: #F5F5F5;
} */

.filters label {
  font-size: 1rem !important;
  font-weight: normal !important;
}

/* .card{
  height: inherit !important;
} */
.gs-text{
  padding-top: 2px;
  white-space: break-spaces !important;
  text-align: left;
}

.gs-block{
  width: 150px;
}

.gs-label{
  /* height: 22px; */
  /* font-size: 12px; */
  text-align: center;
  padding-top: 2px;
  font-weight: bold;
  margin-top: 1px;
  margin-right: 4px;
}
.gs-pill{
  width: 58px;
  float: left;
}

.gs-header{
  text-align: left;
  width: fit-content;
  padding-left: 4px;
  padding-right: 4px;
  margin-left: -4px;
  /* height: 18px; */
  padding-top: 0px;
}

 .gs-label-gold{
  background-color: #FFC627 !important;
}

.gs-label-maroon{
  background-color: #8C1D40 !important;
  color: #FFFFFF !important;
}

.mt-1p{
  margin-top: 1px;
}

.mb-1p{
  margin-bottom: 1px;
}

/*.seat-icon-reserved{
  width: 78px;
  background-color: #E9F5DB;
  border-color: #78BE20;
}

.seat-icon-full{
  width: 45px;
  background-color: #F7DDDD;
  border-color: #B72A2A;
} */

 .circle {
  color: #78BE20
} 

 .triangle {
  color: #78BE20;
  transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -o-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}

.times {
  color: #B72A2A;
  font-size: 1rem;
} 

.switch1 {
  position: relative;
  display: inline-block;
  width: 75px;
  height: 38px;
}

.switch1 input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #a30046;
  ;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider1:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 40px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider1 {
  background-color: #a30046;
  padding-top: 10px;
}

/* input:focus + .slider {
  box-shadow: 0 0 1px #a30046;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
} */

/* Rounded sliders */
.slider1.round {
  border-radius: 38px;
}

.slider1.round:before {
  border-radius: 50%;
}

#banner {
  /* background-color: #E5E5E5 !important; */
  margin-top: 131px;
  margin-bottom: -130px;
  /*height: auto; */
}


#beta_toggle_bar {
  background-color: #E5E5E5;
  font-size: x-small;
  margin-top: 115px;
  margin-bottom: -130px;
}

#beta_toggle_bar_container {
  width: 70%;
  margin: auto;
  font-size: 0.875rem;
  max-width: 1050px;
}

#beta_toggle_bar table {
  width: 100%;
}

#beta_toggle_bar .beta_label {
  text-align: right;
  padding-bottom: 5px;
  padding-right: 8px;
}

.beta_toggle {
  margin-left: 15px;
  margin-top: 10px;
  color: #fff;
  font-weight: 400;
  text-decoration: none !important;
}

.beta_toggle_text {
  padding-top: 15px;
}

.beta_toggle_toggle {
  padding-top: 6px;
  margin-right: -20px;
  font-weight: bold;
}

.quiz-bar {
  background-color: #e5e5e5;
  /* padding-bottom: 20px; */
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.quiz-hyperlink {
  font-weight: bold !important;
  color: #8C1D40 !important;
  text-decoration: none !important;
  cursor: pointer;
  margin-left: 10px;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.quiz-hyperlink:hover {
  text-decoration: underline !important;
}

.quiz-hyperlink:focus {
  box-shadow: 0px 0px 0px 2px #fff, 0px 0px 0px 4px #191919 !important;
}



label>input.bootstrap-asu[type=checkbox]::before {
  font-size: larger;
}

input.bootstrap-asu[type=checkbox]:checked::after {
  background: transparent;
  background-image: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='check' class='svg-inline--fa fa-check fa-w-16' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='currentColor' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'%3E%3C/path%3E%3C/svg%3E");
  height: 1rem;
  width: 1rem;
}

input.bootstrap-asu[type=checkbox]::after,
input.bootstrap-asu[type=radio]::after {
  content: "";
  position: absolute;
  display: inline-block;
  top: 0.1rem;
  width: 0.75rem;
  height: 0.75rem;
  left: 0.1rem
}

input.bootstrap-asu[type=checkbox]::before,
input.bootstrap-asu[type=radio]::before {
  content: "";
  position: absolute;
  display: inline-block;
  left: -0.1rem;
  border: 1px solid #747474;
  width: 1.5rem;
  height: 1.5rem;
  background: #fff;

  top: -0.2rem;
}


input.bootstrap-asu[type=checkbox]:focus::before,
input.bootstrap-asu[type=radio]:focus:before {
  border: 2px solid #191919;
}

input.bootstrap-asu[type=radio]::before {
  border-radius: 50%;
}

input.bootstrap-asu[type=radio]:checked::after {
  background: #000;
  border-radius: 50%;
  top: 0.19rem;
  left: 0.28rem !important;
}

.form-check-label-bootstrap-asu {
  font-weight: 400;
  margin-left: 1rem;
}

form.admin .form-control:focus {
  box-shadow: none !important;
  border: 2px solid #191919 !important;
}

form.admin .filter-check .form-check-input:focus,
.form-check-input:focus{
  outline:2px solid #191919 !important;
}

.border-focus:focus {
  border: 2px solid #191919 !important;
  box-shadow: none !important;
}

/*For skip to main content*/
a.anchor {
  display: block;
  position: relative;
  top: 50px;

  visibility: hidden;
}

#class-details {
  padding-left: 117px;
}

.focus:focus{
  border: 1px solid #000;
  box-shadow: none !important;
}

#home-search .card button,
#catalog-home-search .card button,
button.transparent {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-align: left;
}

#home-search .card,
#home-search .card-img-top,
#catalog-home-search .card,
#catalog-home-search img{
    cursor: pointer;
    border-radius: 0% !important;

}
#home-search .card-img-top{
  width: 130px;

}

#home-search a.card,
#catalog-home-search .cards-row a{
  text-decoration: none !important;
  color: #191919;
}

#home-search .card-title:hover,
#catalog-home-search .card-title:hover{
  text-decoration: underline;
}

#home-search .cards-row a:hover
#catalog-home-search .cards-row a:hover{
  border: 1px solidrgba(0,0,0,.125) !important;
}

/* #home-search .card-body{
  padding: 24px;
} */

.btn-lg-block{
  display: block;
  width: auto;
}

#feedback-section{
  height: 204px;
  width: 100%;
  background: #191919 0% 0% no-repeat padding-box;
  color:white
}

input[type="date"]::-webkit-calendar-picker-indicator {
 margin-right: -9px;
}

/* table.reserved-seats{
  width:100%;
  border-collapse: collapse;

  table-layout:fixed;
}


table.reserved-seats td,
table.reserved-seats th {

  text-overflow: ellipsis;
  overflow: hidden;
   width:100%;

   word-wrap:break-word;
} */


.columns-combined{
  column-width: 140px;
  orphans: 3;
}

div.btn-container{
  /*display: table-cell;
  vertical-align: middle;
   text-align: center; */
  width: 17rem;
  height: 3rem;
}

div.btn-container label {
  font-size: 13px;
  color: #191919;
  font-weight: 500;
}

div.btn-container label:focus {
  box-shadow: none !important;
}

.btn-color-mode-switch{
  display: inline-block;
  margin: 0px;
  position: relative;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner{
  margin: 0px;
  width: 17rem;
  height: 3rem;
  /* background: #E0E0E0; */
  border-radius: 26px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  /*box-shadow: 0px 0px 8px 0px rgba(17, 17, 17, 0.34) inset;*/
  display: block;
  border: 1px solid #212529;
}
.btn-color-mode-switch-inner-focus{
  box-shadow: 0px 0px 0px 2px #fff, 0px 0px 0px 4px #191919 !important;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before{
  content: attr(data-on);
  position: absolute;
  font-size: 16px;
  font-weight: 700;
  top: 11px;
  right: 20px;

}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after{
  content: attr(data-off);
  width: 134px;
  height: 38px;
  background: #8C1D40;
  border-radius: 26px;
  position: absolute;
  left: 4px;
  top: 4px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 6px -2px #111;
  padding: 6px 0px;
  font-size: 16px;
  font-weight: 700;
  color:#ffff
}

.btn-color-mode-switch > .alert{
  display: none;
  background: #FF9800;
  border: none;
  color: #fff;
}

.btn-color-mode-switch input[type="checkbox"]{
  cursor: pointer;
  width: 50px;
  height: 25px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner{
  background: #ffff;
  color: #191919;
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:after{
  content: attr(data-on);
  left: 132px;
  background: #8C1D40;
  color: #ffff
}

.btn-color-mode-switch input[type="checkbox"]:checked + label.btn-color-mode-switch-inner:before{
  content: attr(data-off);
  right: auto;
  left: 20px;
}

.btn-color-mode-switch input[type="checkbox"]:checked ~ .alert{
  display: block;
}

/*Filter and admin search dropdown buttons-menu*/

.custom-dropdown-toggle {
  color: #212529 !important;
  background-color: #fff !important;
  border-color: #191919 !important;
  padding: 0.5rem 0.5rem
}

.custom-dropdown-toggle:focus{
  /* box-shadow: none !important; */
  /* border: 2px solid #191919 !important; */
  box-shadow: 0 0 0 2px #fff,0 0 0 4px #191919!important;
}

.custom-dropdown-toggle svg{
  font-size: 0.7rem;
  float: right;
  margin-top: 2px;
  margin-right: -5px;
  /* margin-left: 10px; */
}

#more-filters-icon{
  float: left;
}

button.btn-advanced-search{
  border-radius: 0% !important;
  height: 40px;
  text-align: left !important;
  padding-left: 1rem !important;
  padding-top: 11px !important;
  padding-right: 12px;
  width: 100% !important;
  font-weight: 100;
  border: 1px solid #747474 !important;
}



button .label-wrap{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 75% ;
  height: 17px;
  float: left;
}

button .cc-label-wrap{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  float: left;
}

.btn-advanced-search:hover{
  transform: none !important;
}

.btn-advanced-search .btn-placeholder{
  color:  #747474;
  font-weight: 100;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80% ;
  height: 17px;
  float: left;
}

.btn-filter:hover,
.btn-filter:not(:disabled):not(.disabled).active,
.btn-filter:not(:disabled):not(.disabled):active,
.filter-dropdown .show>.btn-filter.dropdown-toggle {
  color: #fff !important;
  background-color: #8C1D40 !important;
  border-color: #8C1D40 !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 80% ;
  float: left;
}




/*
.filter-dropdown .filter-check {
  padding-top: 16px
} */

.filter-dropdown .dropdown-menu {
  padding: 16px 16px 16px 16px;
  min-width: 0;
  white-space: nowrap
}

.font-weight-bold{
  font-weight:700
}

.btn-sort,
.btn-sort:hover{
  color:#fff !important;
  background-color: #000 !important;
  border-color: #000 !important;
  cursor: pointer;
  transform: none !important;
}

.sort-dropdown .dropdown-menu{
  padding: 0px !important;
}

 .sort-dropdown .dropdown-item{
  padding: 10px 30px 10px 30px;
 }

 .sort-dropdown .dropdown-item:hover,
 .sort-dropdown .dropdown-item:focus{
  color:#191919;
  background-color: #fafafa;
  /* text-decoration: underline; */
  position: relative;
}

.sort-dropdown .dropdown-item.selected{
  color:#191919;
  background-color: #e8e8e8;

}

.sort-dropdown .dropdown-item.selected .icon{
  margin-left: -21px;
  margin-right: 6px;
}




/* .filter-dropdown .filter-button {
  padding: 1rem;
} */




/*---------------------*/


/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  h1.highlight-gold {
    font-size: 2.25rem;
    line-height: 40px;
  }

  table.reserved-seats td,
  table.reserved-seats th {
    font-size: 2vw;
  }

  .filter-dropdown .dropdown-menu {
    max-width: 350px;
    white-space: break-spaces
  }

  .custom-dropdown-toggle {
    /* max-width: fit-content; */

    white-space: break-spaces !important;
    float: left !important;
    text-align: left !important;
    overflow: unset !important;
  }

  button .cc-label-wrap{
    max-width: 200px;
  }



  /* button .label-wrap{

    width: 57% ;

  } */

}

/* Small devices (landscape phones, less than 768px) */
@media (max-width: 767.98px) {
  .btn-sm-block {
    width: 100% !important;
    display: block !important;
  }



}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .circle {
    margin-top: 1.5px;
  }

  .holder #keyword-label.form-label {
    /* margin-bottom: -50px; */
    padding-left: 0.5rem;
    padding-top: .25rem;
    position: absolute;
    color: #484848;
    z-index: 1;
    font-size: 0.875rem;
  }

  #class-details {
    padding-left: 10px;
  }

}

/* Large devices (desktops, less than 1200px) */
@media (max-width: 1199.98px) {
  /* #beta_toggle_bar{
    background-color: red;
  } */
}

/*Matching the header breakpoint*/
@media (max-width: 1260px) {
  #beta_toggle_bar {

    margin-top: 93px;
  }
  #banner {
    margin-top: 117px;
  }
}


/* Extra small devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  /* .card.filters{
    width: 725px;
  } */

  .border-right-md {
    border-right: 1px solid #dee2e6 !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .btn-lg-block{
    display: block;
    width: 100%;
  }

  .advanced-search-college-padding{
    padding-top: 89px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}

/*All maroon highlighting is custom for GS implementation, missing in Unity*/
.maroon-card{
  border-left:solid 8px #8C1D40 !important;
}

@media (max-width: 768px) {
  .maroon-card{
    border-top:solid 8px #8C1D40 !important;
  }
}

h3 span.highlight-maroon,
h4 span.highlight-maroon {
  -webkit-box-shadow: -0.15em 0 0 #8C1D40, 0.15em 0 0 #8C1D40;
  box-shadow: -0.15em 0 0 #8C1D40, 0.15em 0 0 #8C1D40;
  background: #8C1D40;
  color: #FFFFFF;
}

h1 span.highlight-orange,
h2 span.highlight-orange,
h3 span.highlight-orange {
  -webkit-box-shadow: -0.15em 0 0 #FFC627, 0.15em 0 0 #FFC627;
  box-shadow: -0.15em 0 0 #FFC627, 0.15em 0 0 #FFC627;
  background: #FFC627;
  color: #000000;
}

/*Override Safari default blue by request*/
/*Not accounted for in unity comp core*/
div.uds-anchor-menu-wrapper h4 {
  color: black !important;
}

/*Might cause problems when updating footer*/
#footer-innovation .footer-innovation-links .img-link img {
  height: 65px !important;
}