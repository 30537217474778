/*AnchorMenu floating offset*/
.sticky.with-header {
    top: 88px !important;
}

@media (max-width: 768px) {
    .sticky.with-header {
        top: 115px !important;
    }
}

@media (min-width: 769px) and (max-width: 1281px) {
    .sticky.with-header {
        top: 110px !important;
    }
}

/*Setup relative position for focus boxes*/
.focus-header {
    position: relative;
    padding: 5px !important;
    margin: 10px;
    outline: none;
}

.focus-header:focus::before, .focus-header:focus::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.focus-header:focus::before {
    border: 2px solid white;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
}

.focus-header:focus::after {
    border: 2px solid black;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
}

/*Adjust text and icon position within bounding focus box*/
.faq-accordion-link-text {
    margin-left: 1rem;
}

.faq-accordion-icon {
    margin-right: 1rem;
}
