/* #catalog-home-search{
    padding-left: 0px;
    padding-right: 0px;
} */

#catalog-home-search .bigTitle{
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 2.75rem;
    letter-spacing: -.035em;
}



/* #catalog-home-search .cards-row {
    height:50%
} */

#catalog-home-search .card-img-top {
    height:8rem;
}

#catalog-home-search .card-body {
    height:40%;
    padding: 1rem;
    color: #191919;
   
}




#catalog-home-search .subjects-tab { /* mobile-first */
    columns: 1 auto;
}

@media only screen and (min-width: 600px) { /* tablet */
    #catalog-home-search .subjects-tab {
        columns: 2 auto;
    }
}

@media only screen and (min-width: 768px) { /* laptop*/
    #catalog-home-search .subjects-tab {
        columns: 4 auto;
    }
}

.font-color-asu {
  color: #8C1D40!important;
  font-size: 18px
}

#catalog-college-tabs .subjects-tab .college-subject {
    cursor: pointer;
    page-break-inside: avoid;    /* Theoretically FF 20+ */
    break-inside: avoid-column;  /* Chrome, Safari, IE 11 */
}

/* .college-subjects{
    margin-left: -3px;
} */