/*Make headers of the table bold*/
#class-results #class-results-header{
    font-weight: 700
}


#class-results .class-results-rows,/*making results list behave as a table on large devices*/
#class-results .related-classes .class-accordion{ /*making classes inside the related classes dropdown behave as a table on large devices*/
	display: grid;
    width: 100%;
    grid-template-columns: repeat(13, 1fr) ;/*organice divs on rows of 13 divs each*/
}

#class-results .class-results-cell,
#class-results .class-results-header-cell{
    padding: 12px; /*padding within each cell*/
    border-bottom: 1px solid #D0D0D0; /*table lines*/
}

#class-results .related-classes .class-results-cell{
    border-bottom: none; /*remove lines between classes within the related classes dropdown*/
    background-color:transparent !important
}

#class-results .related-classes .class-results-cell p,
#class-results .related-classes .class-results-cell a,
#class-results .related-classes .class-results-cell div{
    background: none;
}

#class-results .class-accordion-dropdown,
#class-results .related-class-dropdown{
    grid-column: 1 / span 13; /*make the details and related classes dropdowns span the 13 columns of the grid*/
}

/*this will be ignored when determining which cell is part of the grid, this won't be counted on grid-template-columns: repeat(13, 1fr)*/
#class-results .class-accordion,
#class-results .class-results-header{
	display: contents;
}


#class-results .class-accordion.odd .class-results-cell,
#class-results .related-class-dropdown.pt-3.odd.collapse.show,
#class-results .class-accordion-dropdown.odd.collapse .class-details,
.class-results .list-group-item-odd{
    
    background-color: #FAFAFA 
}

#class-results .related-classes .list-group .class-accordion:hover{
    background-color: #D0D0D0;
}

#class-results .class-details,
.class-results .list-group>div:not(.row){
    border: 1px solid #D0D0D0;
    border-left: none;
    border-right: none;
    
}

/* Medium devices (tablets, less than 992px) */
@media (max-width:  991.98px) {

    #class-results .class-results-rows,
    #class-results .class-accordion,
    #class-results .class-subject-number,
    #class-results .related-classes .class-accordion {/*remove grid so that cells now are shown one below the other*/
        display: block;
    }

    #class-results #class-results-header{
        display: none;
    }

    #class-results .class-results-cell,
    #class-results .class-results-header-cell{
        padding: 3px;
        border-bottom: none
    }

    /* #class-results .class-accordion{
        border-bottom: 1px solid #D0D0D0;
        
    } */

    #class-results .class-accordion .course,
    #class-results .class-accordion .days,
    #class-results .class-accordion .instructor,
    #class-results .class-accordion .location,
    #class-results .class-accordion .seats,
    #class-results .class-accordion .syllabus,
    #class-results .class-accordion .gs,
    #class-results .class-accordion .number
    {
        padding-left: 10px;
    }

    #class-results .class-accordion .gs{
        padding-bottom: 20px;
    }

    #class-results .class-accordion .course{
        padding-top: 10px;
       
    }
}

     



/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) { 
    

}