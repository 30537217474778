.uds-charts-and-graphs-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
}

%shared-styles {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  text-align: center;

  h4, span#percentage-display {
    margin: 0.5rem;
    font: normal normal bold 2.5rem Arial;
  }

  span {
    font: normal normal 700 1.125rem Arial;
  }

  @include media-breakpoint-down(md) {
    h4, p {
      font-size: 1.5rem;
    }

    span {
      font-size: 1rem;
    }
  }
}

.uds-charts-and-graphs-overlay {
  @extend %shared-styles;
  justify-content: center;
  width: 60%;
}

.uds-charts-and-graphs-overlay p {
  @extend %shared-styles;
  margin-bottom: 0;
}

canvas {
  width: 100%;
  height: auto;
  position: relative;
}
