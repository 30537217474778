body {
    font-family: Helvetica, sans-serif;
  }
  
  .react-autosuggest__container {
    position: relative;
  }
  
  .react-autosuggest__input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    height: 48px;
    /* width: 101%; */
    
  } 
  .react-autosuggest__input:focus{
  border-color: #2684FF;
    box-shadow: none;
    border-width: 2px;
  }
  .react-autosuggest__input::placeholder{
      color:#969494;
      font-weight: 100
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    /* font-family: Helvetica, sans-serif; */
    font-weight: 300;
    font-size: 15px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 20px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }

  @media (max-width: 575.99px) {
    .react-autosuggest__input{
     border-radius: 4px;
    
  }
}
  