/*--------------------------------------------------------------
Desktop styles
--------------------------------------------------------------*/
.uds-person-profile {
  --max-width: 800px;
  --pic-size: 180px;
  --person-profession-bottom-margin: 16px;
  --person-name-size: 24px;
  --name-top-margin: 8px;
  &.small {
    --pic-size: 120px;
  }
  &.micro {
    --max-width: 384px;
    --pic-size: 78px;
    --person-profession-bottom-margin: 8px;
    --person-name-size: 20px;
    --name-top-margin: 0;

    .person-profession {
      h4:not(:first-child) {
        display: none;
      }
    }
  }
  display: flex;
  max-width: var(--max-width);

  &.fill {
    background-color: $uds-color-base-gray-1;
    border: 1px solid $uds-color-base-gray-3;
    padding: 32px;
  }
  .profile-img-container {
    width: var(--pic-size);
    height: 100%;

    .profile-img-placeholder {
      background-size: 100%;
      background-size: cover;
      width: var(--pic-size);
      height: var(--pic-size);

      .profile-img {
        width: var(--pic-size);
        height: var(--pic-size);
        border-radius: 100%;
      }
    }
  }
  .person {
    margin-left: $uds-size-spacing-3;
    &-name {
      line-height: 1;
      font-size: var(--person-name-size);
      margin-top: var(--name-top-margin);
      margin-bottom: $uds-size-spacing-1;
    }
    &-profession {
      margin: 0;
      line-height: 1;
      margin-bottom: var(--person-profession-bottom-margin);
      h4 {
        margin: 0;
        font-size: $uds-size-font-medium;
      }
    }
    .more-link {
      line-height: 1;
    }
    ul {
      list-style-type: none;
      display: flex;
      padding-left: 0;
      gap: .5rem 1rem;
      li:nth-child(-n + 2) {
        white-space: nowrap;
      }
      &.person-contact-info {
        margin-bottom: $uds-size-spacing-2;
        .person-address {
          display: flex;
          flex-direction: column;
          margin: 0;
          span {
            font-weight: normal;
          }
        }
      }
      &.person-social-medias a {
        color: $uds-color-base-gray-7;
        font-size: 1.75rem;
      }
    }
  }
}
/*--------------------------------------------------------------
Mobile Styles
--------------------------------------------------------------*/
@include media-breakpoint-down(md) {
  .uds-person-profile {
    max-width: 300px;
    flex-direction: column;
    .person {
      margin: 0;
      &-name {
        margin-top: $uds-size-spacing-3;
      }
      &-description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
      }
      ul.person-contact-info {
        flex-direction: column;
        li {
          margin-top: $uds-size-spacing-1;
          margin-left: 0;
        }
      }
    }
  }
}
