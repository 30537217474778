
$morse-code-white: url($image-assets-path + "/background-patterns/MorseCodeWhite.png");
$morse-code-black: url($image-assets-path + "/background-patterns/MorseCodeBlack.png");
$network-black: url($image-assets-path + "/background-patterns/NetworkBlack.png");
$network-white: url($image-assets-path + "/background-patterns/NetworkWhite.png");
$topo-black: url($image-assets-path + "/background-patterns/TopoPatternBlack.png");
$topo-white: url($image-assets-path + "/background-patterns/TopoPatternWhite.png");
$semiconductor-light: url($image-assets-path + "/background-patterns/SemiconductorLight.png");
$semiconductor-dark: url($image-assets-path + "/background-patterns/SemiconductorDark.png");
$plus-light: url($image-assets-path + "/background-patterns/PlusLight.png");
$plus-dark: url($image-assets-path + "/background-patterns/PlusDark.png");
$arrows-light: url($image-assets-path + "/background-patterns/ArrowsLight.png");
$arrows-dark: url($image-assets-path + "/background-patterns/ArrowsDark.png");
